<template>
  <div class="edit-scale">
    <div class="edit-scale__sidebar">
      <Sidebar />
    </div>
    <div class="edit-scale__container">
      <h1 class="edit-scale__container__tittle">Bids</h1>
      <hr class="edit-scale__container__separator" />
      <Button
        icon="pi pi-arrow-left"
        class="p-button-rounded p-button-outlined edit-scale__container__back-button"
        @click="backToScale()"
      />
      <div class="edit-scale__container__content">
        <b-form-file
          accept=".xlsx, .xls"
          placeholder="Choose or drop a file"
          v-model="file"
        >
        </b-form-file>
        <button @click="clearFile" v-if="file" class="clear-button">
          <b-icon icon="x-circle" scale="1"></b-icon></button>
        <Table :file="file"/>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import Sidebar from '../../components/Sidebar.vue';
import Table from './components/Table.vue';

export default {
  name: 'EditScale',
  components: {
    Sidebar,
    Table,
  },
  data() {
    return {
      totalErrors: 0,
      file: null,
    };
  },
  created() {
    const subject = this.$store.getters['scale/showSubject'];
    if (!subject) {
      this.backToScale();
    }
  },
  methods: {
    backToScale() {
      this.$router.push({ name: 'Scales' });
    },
    clearFile() {
      this.file = null;
      this.$router.push({ name: 'Scales' });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-scale {
  height: 90vh;
  display: flex;
  &__sidebar {
    width: 9%;
  }
  &__container {
    width: 91%;
    margin: 2rem;
    &__tittle {
      font-weight: bold;
    }
    &__separator {
      width: 98%;
      margin: 2rem auto;
    }
    &__back-button {
      @include button-outlined;
      margin-bottom: 2rem;
      float: left;
      left: 0.5rem;
    }
    &__content {
      width: 100%;
      margin: 2rem auto;
      height: 70rem;
      &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 2.5rem 0 1rem;
        &--button {
          @include primary-button;
          margin-right: 0.5rem;
        }
      }
      &--button {
        @include primary-button;
        margin-right: 0.5rem;
      }
    }
  }
}
.clear-button{
  border: none;
  background-color: transparent;
  z-index: 15;
  bottom: 3%;
  position: relative;
  left: 40%;
}
:deep(.custom-file-label::after) {
  color: $color-white;
  background-color: $color-primary-button;
  border-color: $color-primary-button;
}
:deep(thead) {
  background-color: #efeff0;
  color: $canned-tables-color;
}
</style>
